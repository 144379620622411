<template>
  <section class="ground-floor-house">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>La maison de plain-pied : avantages et inconvénients</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/ground-floor-house-1.jpg"
            alt="La maison de plain-pied : avantages et inconvénients<"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Une maison au top sur les aménagements et la circulation !</h2>
              <p>Vous avez décidé de passer à l’action en devenant propriétaire de votre maison individuelle neuve, mais quel modèle de maison allez-vous sélectionner ? Maison plain-pied ou maison à étage ?</p>
              <p>Si vous optez pour le plain-pied, sachez que ce type de maison offre une plus grande simplicité d’aménagement. A superficie équivalente, la maison de plain-pied vous fera bénéficier de plus grands volumes, avec des avantages notables sur les possibilités d’organisation des espaces de vie. La circulation sera plus facile et les échanges entre pièces plus naturels. Il faut également penser aux conséquences sur l’entretien avec un ménage et des tâches ménagères plus aisées et donc plus rapides, sans les difficultés liées aux changements de niveaux et à l’escalier lui-même. Maisons Alysia vous propose un catalogue très fourni en plans de maisons de plain-pied, de quoi assurément trouver votre bonheur !</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/ground-floor-house-4.jpg"
              alt="Une maison au top sur les aménagements et la circulation !"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>Idéale pour les grands terrains, indispensable dans le cadre du handicap</h2>
              <p>La maison de plain-pied est un type de plan à envisager sur les grands terrains, en zones diffues ou également en lotissements, ce qui fréquemment l’exclut des possibilités plus réduites en zones urbaines pour lesquelles la seule solution réside la plupart du temps à l’adoption de la maison à étage. Sans parler de l’esthétisme de la maison de plain-pied (qui relève en fait du goût de chacun), celle-ci permet de nombreuses possibilités en termes d’aménagements extérieurs et de prolongements des pièces vers l’extérieur.</p>
              <p>Enfin, le facteur sécurité privilégie la maison de plain-pied : cela concerne bien sûr l’escalier, cause d’accidents domestiques, pour les familles avec des enfants en bas âge ou pour des occupants seniors. Cette circulation optimisée et sécurisée convient alors parfaitement aux personnes à mobilité réduite (PMR) ou présentant un handicap moteur.</p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img
              src="@/assets/img/pages/ground-floor-house-5.jpg"
              alt="Idéale pour les grands terrains, indispensable dans le cadre du handicap"
            />
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'ground-floor-house',
};
</script>

<style lang="sass">
.ground-floor-house
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 30rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
      .smartpage-costing
        @include smartpage-costing
      .smartpage-card-img
        margin-top: -30%
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
        .smartpage-card-img
          margin-top: 1.5rem
        .smartpage-card-reverse
          .smartpage-card-img
            margin-bottom: 0
      .card + .card
        margin-top: 1.5rem
</style>
